import { v4 as uuidv4 } from 'uuid';
import { Reducer } from '@reduxjs/toolkit';

import { TilliggendeRom } from '../../components/registering/utils/registerEnums';
import { EnergyPlan } from '../../types/building';
import {
  Konstruksjonstetthet,
  Terrengskjerming,
  UserInput,
  Vindu
} from '../../types/registration/userInput';
import { sanitize } from '../../utils/sanitize';

import {
  RegistrationAction,
  RegistrationActionTypes,
  RegistrationStore
} from './types';
import { updateBeregningskjerneinput } from './utils';

const initStateTransformer = (
  userInput?: UserInput,
  energiplan?: Partial<EnergyPlan>
): RegistrationStore => {
  if (!userInput) {
    return {
      allFloorsSameShape: true,
      allFloorsSameWallConstruction: true,
      allWallsAgainstTheOpen: false,
      userInput: {
        bygningsdetaljer: {
          etasjer: []
        },
        detaljeringssteg: {
          bygningsform: false,
          energibruk: false,
          vegger: false,
          vinduer: false,
          gulv: false,
          lekkasjetall: false,
          takkonstruksjon: false,
          ytterdorer: false
        }
      }
    };
  }
  const sanitizedInitValues = sanitize(userInput);

  // allFloorsSameShape, allFloorsSameWallConstruction and allWallsAgainstTheOpen should
  // probably be looked at. Dont know why the client should have to loop all of this data to
  // be able to set those three properties. Maybe these properties should be sent from the backend.

  const allFloorsSameShape = sanitizedInitValues.bygningsdetaljer?.etasjer
    ? Array.from(
        new Set(
          sanitizedInitValues.bygningsdetaljer?.etasjer?.map((etasje) =>
            [
              etasje.bygningsForm,
              ...(etasje.vegger?.map(
                (vegg) =>
                  `${vegg.veggId}${vegg.himmelretning}${vegg.lengdeIMeter}${etasje.gjennomsnittligEtasjehoyde}`
              ) || [])
            ].join()
          )
        )
      ).length === 1
    : true;

  const allFloorsSameWallConstruction = allFloorsSameShape
    ? sanitizedInitValues.bygningsdetaljer?.etasjer
      ? Array.from(
          new Set(
            sanitizedInitValues.bygningsdetaljer?.etasjer?.map((etasje) =>
              [
                etasje.bygningsForm,
                ...(etasje.vegger?.map(
                  (vegg) =>
                    `${vegg.veggId}${vegg.himmelretning}${vegg.veggSoner
                      .map((veggsone) =>
                        JSON.stringify(veggsone, (key, value) =>
                          key !== 'id' && key !== 'vinduer' ? value : undefined
                        )
                      )
                      .join('')}`
                ) || [])
              ].join()
            )
          )
        ).length === 1
      : true
    : false;

  const allWallsAgainstTheOpen =
    !sanitizedInitValues.bygningsdetaljer?.etasjer?.some((etasje) =>
      etasje.vegger?.some((vegg) =>
        vegg.veggSoner?.some(
          (vs) => vs.tilliggendeRom !== TilliggendeRom.MotDetFri
        )
      )
    );

  return {
    allFloorsSameShape,
    allFloorsSameWallConstruction,
    allWallsAgainstTheOpen,
    energiplan: energiplan ?? { energiplanId: uuidv4() },
    userInput: sanitizedInitValues
  };
};

export const recursivelyHandleNestedChange = <T>(
  object: Record<string, unknown> = {},
  path: string[],
  value: unknown
): T => {
  if (path.length === 1 && value === undefined) {
    delete object[path[0]];
    return object as T;
  }

  const key = path.shift();

  return (
    key != null
      ? {
          ...object,
          [key]:
            path.length > 0
              ? recursivelyHandleNestedChange(
                  object[key] as Record<string, unknown>,
                  path,
                  value
                )
              : value
        }
      : value
  ) as T;
};

const registerReducer: Reducer<RegistrationStore, RegistrationActionTypes> = (
  state = initStateTransformer({} as UserInput),
  action
) => {
  switch (action.type) {
    case RegistrationAction.UPDATE_PARAMETERS: {
      if (!action.payload.data) {
        return state;
      }

      const { data } = action.payload;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          energimerke: {
            ...state?.energiplan?.energimerke,
            inndata: {
              ...state?.energiplan?.energimerke?.inndata,
              beregningskjerneInput: updateBeregningskjerneinput(
                state.energiplan?.energimerke?.inndata?.beregningskjerneInput,
                data
              )
            }
          }
        } as EnergyPlan
      };
    }

    case RegistrationAction.WALL_HAS_NO_WINDOWS: {
      const { etasjeId, veggId, value } = action.payload;

      const updatedEtasjer = state.userInput.bygningsdetaljer.etasjer.map(
        (etasje) => {
          if (etasje.id === etasjeId) {
            const updatedVegger = etasje.vegger?.map((vegg) => {
              if (vegg.veggId === veggId) {
                return { ...vegg, hasWindows: value };
              }

              return vegg;
            });

            return { ...etasje, vegger: updatedVegger };
          }

          return etasje;
        }
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput.bygningsdetaljer,
            etasjer: updatedEtasjer
          }
        }
      };
    }

    case RegistrationAction.INIT_REGISTRATION: {
      return initStateTransformer(undefined);
    }

    case RegistrationAction.UPDATE_REGISTRATION: {
      const {
        payload: { energiplan = {}, userInput = {} as UserInput }
      } = action;

      const transformedState = initStateTransformer(userInput, energiplan);

      // TODO: Fix duplicated logic.
      // initStateTransformer programmatically flips the allFloorsSameShape flag if floors are identical
      // - need to update calculatedArea
      const firstEtasjeId =
        transformedState.userInput?.bygningsdetaljer?.etasjer?.[0].id;
      const firstEtasjeCalculatedArea = {
        [firstEtasjeId]: state?.calculatedArea?.[firstEtasjeId]
      };

      // Remove all floors except the first one when all floors are toggled to be the same shape
      return {
        calculatedArea: transformedState.allFloorsSameShape
          ? firstEtasjeCalculatedArea
          : state.calculatedArea,
        ...transformedState
      };
    }

    case RegistrationAction.INIT_SOKNAD: {
      const userInput = {
        detaljeringssteg: {
          bygningsform: true,
          takkonstruksjon: true,
          vegger: true,
          vinduer: true,
          gulv: true,
          ytterdorer: true,
          energibruk: false,
          lekkasjetall: true
        },
        bygningsdetaljer: {
          etasjer: []
        }
      };

      return initStateTransformer(userInput);
    }

    case RegistrationAction.UPDATE_CALCULATED_AREA: {
      const { area, etasjeId } = action.payload;

      return {
        ...state,
        calculatedArea: {
          ...state.calculatedArea,
          [etasjeId]: area
        }
      };
    }

    case RegistrationAction.DOR_ADD: {
      const {
        payload: { dor }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            ytterDorer: [
              ...(state.userInput?.bygningsdetaljer?.ytterDorer || []),
              dor
            ]
          }
        }
      };
    }

    case RegistrationAction.DOR_REMOVE: {
      const {
        payload: { dorId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            ytterDorer: state.userInput?.bygningsdetaljer?.ytterDorer?.filter(
              (dor) => dor.id !== dorId
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_UPDATE: {
      const { payload: updatedEtasje } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => {
                if (updatedEtasje.id === etasje.id) {
                  return updatedEtasje;
                }
                return etasje;
              }
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_ADD: {
      const {
        payload: { etasjeId, veggId, veggsone }
      } = action;

      const newVeggsone = {
        ...veggsone,
        id: uuidv4()
      };

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: [...vegg.veggSoner, newVeggsone]
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { etasjeId, veggId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: hasMultipleZones
                              ? [
                                  ...vegg.veggSoner,
                                  {
                                    id: uuidv4(),
                                    ...(state.allWallsAgainstTheOpen
                                      ? {
                                          tilliggendeRom:
                                            TilliggendeRom.MotDetFri
                                        }
                                      : {})
                                  }
                                ]
                              : vegg.veggSoner?.splice(0, 1)?.map((vs) => ({
                                  ...vs,
                                  lengdeIMeter: vegg.lengdeIMeter
                                }))
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_REMOVE: {
      const {
        payload: { veggsoneId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => ({
                  ...vegg,
                  veggSoner: vegg.veggSoner?.filter(
                    (vs) => vs.id !== veggsoneId
                  )
                }))
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_UPDATE: {
      const {
        payload: { veggsone }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => ({
                  ...vegg,
                  veggSoner: vegg.veggSoner?.map((vs) => {
                    if (vs.id !== veggsone.id) {
                      return vs;
                    }

                    return {
                      ...veggsone,
                      id: vs.id,
                      vinduer: vs.vinduer
                    };
                  })
                }))
              })
            )
          }
        }
      };
    }

    case RegistrationAction.VEGGKONSTRUKSJON_COPY: {
      const { etasjeId, fraVeggsoneId, tilVeggsoneId } = action.payload;

      const etasje = state.userInput.bygningsdetaljer.etasjer.find(
        (etg) => etg.id === etasjeId
      );

      // Get wall construction from selected wall zone (fraVeggsoneId)
      const copiedVeggkonstruksjon = etasje?.vegger
        ?.flatMap((vegg) => vegg.veggSoner)
        .find((veggsone) => veggsone.id === fraVeggsoneId)?.veggKonstruksjon;

      if (copiedVeggkonstruksjon === null) {
        return { ...state };
      }

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etg) => ({
              ...etg,
              vegger: etg.vegger?.map((vegg) => ({
                ...vegg,
                veggSoner: vegg.veggSoner?.map((veggsone) => {
                  // Update target wall zone with copied wall construction (tilVeggsoneId)
                  if (veggsone.id === tilVeggsoneId) {
                    return {
                      ...veggsone,
                      veggKonstruksjon: copiedVeggkonstruksjon
                    };
                  }
                  return veggsone;
                })
              }))
            }))
          }
        }
      };
    }

    case RegistrationAction.ETASJER_UPDATE: {
      const {
        payload: { etasje }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((e) => {
              return {
                ...e,
                gjennomsnittligEtasjehoyde: etasje.gjennomsnittligEtasjehoyde,
                vegger: etasje.vegger,
                bygningsForm: etasje.bygningsForm,
                id: e.id ?? uuidv4()
              };
            })
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_ADD: {
      const {
        payload: { veggId, veggsone }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: [
                          ...vegg.veggSoner,
                          {
                            ...veggsone,
                            id: uuidv4()
                          }
                        ]
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { veggId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: hasMultipleZones
                          ? [
                              ...vegg.veggSoner,
                              {
                                id: uuidv4(),
                                ...(state.allWallsAgainstTheOpen
                                  ? {
                                      tilliggendeRom: TilliggendeRom.MotDetFri
                                    }
                                  : {})
                              }
                            ]
                          : vegg.veggSoner?.splice(0, 1)?.map((vs) => ({
                              ...vs,
                              lengdeIMeter: vegg.lengdeIMeter
                            }))
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_REMOVE: {
      const {
        payload: { veggId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: vegg.veggSoner?.splice(
                          0,
                          vegg.veggSoner?.length - 1
                        )
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_UPDATE: {
      const {
        payload: { veggId, veggsone, veggsoneIndex }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => {
                  if (vegg.veggId !== veggId) {
                    return vegg;
                  }

                  return {
                    ...vegg,
                    veggSoner: vegg.veggSoner?.map((vs, vsi) => {
                      if (vsi !== veggsoneIndex) {
                        return vs;
                      }

                      return {
                        ...veggsone,
                        id: vs.id,
                        vinduer: vs.vinduer
                      };
                    })
                  };
                })
              })
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_ADD: {
      const {
        payload: { etasjeId, veggId, veggsoneId, windows: vindu }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) =>
                              veggsone.id === veggsoneId
                                ? {
                                    ...veggsone,
                                    vinduer: [
                                      ...(veggsone.vinduer || []),
                                      ...vindu
                                    ]
                                  }
                                : veggsone
                            )
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_REMOVE: {
      const {
        payload: { etasjeId, veggId, veggsoneId, vinduIds: vinduId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) =>
                              veggsone.id === veggsoneId
                                ? {
                                    ...veggsone,
                                    vinduer: veggsone.vinduer?.filter(
                                      (vindu) => !vinduId.includes(vindu.id)
                                    )
                                  }
                                : veggsone
                            )
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_EDIT: {
      const {
        payload: { etasjeId, veggId, veggsoneId, windows: vindu, vinduIds }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) => {
                              if (veggsone.id === veggsoneId) {
                                // Filter out all the windows that are to be edited and replace them with new ones
                                const filteredWindows =
                                  veggsone.vinduer?.filter(
                                    (w) => !vinduIds.includes(w.id)
                                  ) as Vindu[];

                                return {
                                  ...veggsone,
                                  vinduer: [...filteredWindows, ...vindu]
                                };
                              }

                              return veggsone;
                            })
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_INIT: {
      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                gulvSoner:
                  etasje.gulvSoner?.length || 0 > 0
                    ? etasje.gulvSoner?.map((sone) => ({
                        ...sone,
                        id: sone.id || uuidv4()
                      }))
                    : [{ id: uuidv4() }]
              })
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_ADD: {
      const {
        payload: { etasjeId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: [...(etasje.gulvSoner || []), { id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULV_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { etasjeId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: hasMultipleZones
                      ? [...(etasje.gulvSoner || []), { id: uuidv4() }]
                      : etasje.gulvSoner?.splice(0, 1) || [{ id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_REMOVE: {
      const {
        payload: { etasjeId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: etasje.gulvSoner?.splice(
                      0,
                      etasje.gulvSoner?.length - 1
                    ) || [{ id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GJENSTAENDE_TILTAK_ADD: {
      const {
        payload: { tiltak }
      } = action;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          gjenstaendeTiltak: [
            ...(state.energiplan?.gjenstaendeTiltak || []),
            tiltak
          ]
        }
      };
    }

    case RegistrationAction.GJENSTAENDE_TILTAK_REMOVE: {
      const {
        payload: { tiltakId }
      } = action;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          gjenstaendeTiltak: state.energiplan?.gjenstaendeTiltak?.filter(
            (tiltak) => tiltak.tiltakId != tiltakId
          )
        }
      };
    }

    case RegistrationAction.TAK_UPDATE: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              [field]: value
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_ADD: {
      const {
        payload: { vindu }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: [
                ...(state.userInput?.bygningsdetaljer?.tak?.vinduer || []),
                vindu
              ]
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_EDIT: {
      const {
        payload: { vindu }
      } = action;

      const { id, ...rest } = vindu;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: state.userInput.bygningsdetaljer.tak?.vinduer?.map(
                (window) => (window.id === id ? { id, ...rest } : window)
              )
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_REMOVE: {
      const {
        payload: { vinduIds }
      } = action;

      // Since it is not possible to define several of the same roof window for now
      // we only need to retrieve the first id in this case.
      const vinduId = vinduIds?.[0];

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: state.userInput?.bygningsdetaljer?.tak?.vinduer?.filter(
                (vindu) => vindu.id !== vinduId
              )
            }
          }
        }
      };
    }

    case RegistrationAction.TOGGLE_ALL_FLOORS_SAME_SHAPE: {
      const {
        payload: { value }
      } = action;

      if (value) {
        const firstEtasjeItem = state.userInput?.bygningsdetaljer?.etasjer?.[0];
        const firstEtasjeCalculatedArea =
          state?.calculatedArea?.[firstEtasjeItem.id];

        return {
          ...state,
          // Remove all floors except the first one when all floors are toggled to be the same shape
          calculatedArea: {
            [firstEtasjeItem.id]: firstEtasjeCalculatedArea
          },
          allFloorsSameShape: true,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                (etasje, index) =>
                  index === 0
                    ? etasje
                    : {
                        ...etasje,
                        bygningsForm: firstEtasjeItem?.bygningsForm,
                        gjennomsnittligEtasjehoyde:
                          firstEtasjeItem?.gjennomsnittligEtasjehoyde,
                        vegger:
                          // Copy walls from first floor and make sure wall zones has unique ids
                          firstEtasjeItem?.vegger?.map((vegg) => ({
                            veggId: vegg.veggId,
                            himmelretning: vegg.himmelretning,
                            lengdeIMeter: vegg.lengdeIMeter,
                            veggSoner: vegg.veggSoner.map((veggsone) => ({
                              ...veggsone,
                              id: uuidv4()
                            }))
                          })) || []
                      }
              )
            }
          }
        };
      }

      return {
        ...state,
        allFloorsSameShape: false,
        allFloorsSameWallConstruction: false
      };
    }

    case RegistrationAction.TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR: {
      const {
        payload: { value: allFloorsSameWallConstruction }
      } = action;

      // If all floors have same construction - use walls from floor index 0
      if (allFloorsSameWallConstruction) {
        const firstEtasjeItem = state.userInput?.bygningsdetaljer?.etasjer?.[0];

        return {
          ...state,
          allFloorsSameWallConstruction: true,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                (etasje, etasjeIndex) =>
                  etasjeIndex === 0
                    ? etasje
                    : {
                        ...etasje,
                        vegger: etasje.vegger?.map((vegg, veggIndex) => ({
                          ...vegg,
                          veggSoner: firstEtasjeItem?.vegger?.[
                            veggIndex
                          ]?.veggSoner?.map((veggsone) => ({
                            ...veggsone,
                            id: uuidv4()
                          })) || [{ id: uuidv4() }]
                        }))
                      }
              )
            }
          }
        };
      }

      return {
        ...state,
        allFloorsSameWallConstruction: false
      };
    }

    case RegistrationAction.TOGGLE_ALL_WALLS_AGAINST_THE_OPEN: {
      const {
        payload: { value }
      } = action;

      return value
        ? {
            ...state,
            allWallsAgainstTheOpen: true,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) =>
                    etasje.erKjeller
                      ? etasje
                      : {
                          ...etasje,
                          vegger: etasje.vegger?.map((vegg) => ({
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((vs) => ({
                              ...vs,
                              tilliggendeRom: TilliggendeRom.MotDetFri
                            }))
                          }))
                        }
                )
              }
            }
          }
        : {
            ...state,
            allWallsAgainstTheOpen: false
          };
    }

    case RegistrationAction.UPDATE_BYGNINGSDETALJERING: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            [field]: value
          }
        }
      };
    }

    case RegistrationAction.UPDATE_TERRENGSKJERMING: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            terrengskjerming: {
              ...state.userInput?.bygningsdetaljer?.terrengskjerming,
              [field]: value
            } as Terrengskjerming
          }
        }
      };
    }

    case RegistrationAction.UPDATE_ENERGIBRUK: {
      const {
        payload: {
          energibruk: { id, ar, energienhet, energikilde, forbruk }
        }
      } = action;

      if (forbruk != null) {
        return {
          ...state,
          userInput: {
            ...state.userInput,
            energibruk: state.userInput?.energibruk?.find(
              (item) => item.ar === ar && item.energikilde === energikilde
            )
              ? state.userInput?.energibruk.map((item) =>
                  item.ar === ar && item.energikilde === energikilde
                    ? { ...item, forbruk }
                    : item
                )
              : [
                  ...(state.userInput?.energibruk || []),
                  {
                    id: id ?? uuidv4(),
                    ar,
                    energikilde,
                    forbruk,
                    energienhet
                  }
                ]
          }
        };
      }

      return {
        ...state,
        userInput: {
          ...state.userInput,
          energibruk: (state.userInput?.energibruk || []).filter(
            (item) => !(item.ar === ar && item.energikilde === energikilde)
          )
        }
      };
    }

    case RegistrationAction.UPDATE_ENERGIBRUK_UNIT: {
      const {
        payload: { energienhet, energikilde }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          energibruk: state.userInput?.energibruk?.map((item) =>
            item.energikilde === energikilde
              ? {
                  ...item,
                  energienhet
                }
              : item
          )
        }
      };
    }

    case RegistrationAction.UPDATE_KONSTRUKSJONSTETTHET: {
      const {
        payload: { data }
      } = action;

      const updatedKonstruksjonstetthet: Konstruksjonstetthet = {
        ...state.userInput?.bygningsdetaljer?.konstruksjonstetthet,
        dato: data.dato,
        lekkasjetall: data.lekkasjetall
      };

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            konstruksjonstetthet: {
              ...state.userInput?.bygningsdetaljer?.konstruksjonstetthet,
              ...updatedKonstruksjonstetthet
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default registerReducer;
