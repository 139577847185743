import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  ByggType,
  DetailingStep,
  JaNeiVetIkke,
  OppvarmingBruk,
  PlasseringAvEnhetIByggVertikalt,
  TilliggendeRom,
  Varmekilde,
  Varmesentral,
  VentilasjonsType
} from '../../components/registering/utils/registerEnums';
import {
  Etasje,
  OppvarmingVarmepumpe,
  UserInput
} from '../../types/registration/userInput';
import {
  energySourceUnitMap,
  TekniskUtstyrKey,
  TekniskUtstyrKeys
} from '../../utils/register';

import registerReducer, { recursivelyHandleNestedChange } from './reducer';
import {
  GulvsoneUpdateAction,
  RegistrationAction,
  RegistrationActionTypes,
  RegistrationStore,
  UpdateDetailingStepsAction,
  UpdateRegistrationFieldAction
} from './types';

const initialState: RegistrationStore = {
  allFloorsSameShape: true,
  allFloorsSameWallConstruction: true,
  allWallsAgainstTheOpen: false,
  userInput: {
    bygningsdetaljer: {
      etasjer: []
    },
    detaljeringssteg: {
      bygningsform: false,
      energibruk: false,
      vegger: false,
      vinduer: false,
      gulv: false,
      lekkasjetall: false,
      takkonstruksjon: false,
      ytterdorer: false
    }
  }
};

// Wrap the old reducer in a Redux Toolkit reducer as a step in reducer rewrite
export const registrationReducer = createReducer(initialState, (builder) => {
  // This will be split and rewritten when DynamicField is removed
  builder.addCase(
    RegistrationAction.UPDATE_REGISTRATION_FIELD,
    (state, action) => {
      const {
        payload: { objectPath, value }
      } = action as UpdateRegistrationFieldAction;

      const field = objectPath[objectPath.length - 1];

      if (field === 'solfangerMedVannbarenVarme' && !!value) {
        if (state.userInput.tekniskUtstyr?.oppvarmingSol === undefined) {
          state.userInput.tekniskUtstyr = {};
          state.userInput.tekniskUtstyr.oppvarmingSol = {};
        }

        state.userInput.tekniskUtstyr.oppvarmingSol.solfangerMedVannbarenVarme =
          {
            serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
            bruk: OppvarmingBruk.Ukjent
          };
        return;
      }

      if (field === 'ventilasjonType') {
        if (value === VentilasjonsType.Balansert) {
          state.userInput.ventilasjon = {
            [field]: value as VentilasjonsType,
            serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
            gjenvinningAvVarme: JaNeiVetIkke.VetIkke
          };
          return;
        }

        if (
          state.userInput?.ventilasjon?.ventilasjonType ===
          VentilasjonsType.Balansert
        ) {
          state.userInput.ventilasjon = {
            [field]: value as VentilasjonsType
          };
          return;
        }
      }

      if (
        field === 'gjenvinningAvVarme' &&
        state.userInput?.ventilasjon?.gjenvinningAvVarme === JaNeiVetIkke.Ja &&
        value !== JaNeiVetIkke.Ja
      ) {
        delete state.userInput?.ventilasjon?.gjenvinningsType;

        state.userInput.ventilasjon.gjenvinningAvVarme = value as JaNeiVetIkke;
        return;
      }

      if (
        field === 'varmeKilde' &&
        objectPath.includes('oppvarmingVarmepumpe')
      ) {
        if (
          value === Varmekilde.LuftFraVentilasjonsanlegg &&
          state.userInput?.ventilasjon?.ventilasjonType &&
          ![
            VentilasjonsType.Balansert,
            VentilasjonsType.MekaniskAvtrekk
          ].includes(state.userInput?.ventilasjon?.ventilasjonType)
        ) {
          delete state.userInput?.ventilasjon?.ventilasjonType;

          state.userInput.tekniskUtstyr = {
            ...state.userInput?.tekniskUtstyr,
            oppvarmingVarmepumpe: {
              [field]: value as Varmekilde
            }
          };
          return;
        }
      }
      if (field === 'oppvarmingVarmepumpe.varmeKilde.type') {
        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.punktoppvarming;

        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.vannbarenVarme;

        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.ventilasjon;

        if (state.userInput.tekniskUtstyr === undefined) {
          state.userInput.tekniskUtstyr = {};
        }

        state.userInput.tekniskUtstyr.oppvarmingVarmepumpe = {
          ...state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe,
          [value as keyof OppvarmingVarmepumpe]: {
            serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
            ...(value === 'vannbarenVarme' || value === 'ventilasjon'
              ? { bruk: OppvarmingBruk.Ukjent }
              : {}),
            ...(value === 'ventilasjon'
              ? { varmesentral: Varmesentral.Ukjent }
              : {})
          }
        };
        return;
      }

      if (field === 'byggType') {
        delete state.userInput?.bygningsdetaljer
          ?.plasseringAvEnhetIByggHorisontalt;
        delete state.userInput?.bygningsdetaljer
          ?.plasseringAvEnhetIByggVertikalt;

        state.userInput.bygningsdetaljer.byggType = value as ByggType;
        return;
      }

      if (
        field === 'plasseringAvEnhetIByggVertikalt' &&
        value !== PlasseringAvEnhetIByggVertikalt.OversteEtasje
      ) {
        delete state.userInput?.bygningsdetaljer?.tak;

        state.userInput.bygningsdetaljer.plasseringAvEnhetIByggVertikalt =
          value as PlasseringAvEnhetIByggVertikalt;
        state.userInput.detaljeringssteg[DetailingStep.Takkonstruksjon] = false;
        return;
      }

      if (
        [
          'antallEtasjerUnntattKjeller',
          'basement',
          'harBoligenKjeller'
        ].includes(field)
      ) {
        let etasjer = state.userInput?.bygningsdetaljer?.etasjer;
        // Delete all state related to calculation of area when data related to etasjer changes.
        // Not proud of this but it is the best of two evils.
        delete state.calculatedArea;

        if (field === 'antallEtasjerUnntattKjeller') {
          if (value == null) {
            delete state.userInput?.bygningsdetaljer
              ?.antallEtasjerUnntattKjeller;

            state.userInput.bygningsdetaljer.etasjer = etasjer?.filter(
              ({ erKjeller }) => erKjeller
            );
            return;
          }

          const numericValue = value as number;

          const nonBasementFloorCount =
            state.userInput?.bygningsdetaljer?.etasjer?.filter(
              (etasje) => !etasje.erKjeller
            )?.length || 0;

          if (numericValue != null) {
            if (numericValue > nonBasementFloorCount) {
              const firstFloor =
                state.userInput?.bygningsdetaljer?.etasjer?.[0];

              const inheritedValues: Partial<Etasje> = state.allFloorsSameShape
                ? {
                    bygningsForm: firstFloor?.bygningsForm,
                    gjennomsnittligEtasjehoyde:
                      firstFloor?.gjennomsnittligEtasjehoyde,
                    vegger: firstFloor?.vegger?.map(({ veggSoner, ...rest }) =>
                      state.allFloorsSameWallConstruction
                        ? {
                            ...rest,
                            veggSoner: veggSoner.map(
                              // eslint-disable-next-line
                              ({ vinduer, ...veggsoneAttributes }) => ({
                                ...veggsoneAttributes,
                                id: uuidv4()
                              })
                            )
                          }
                        : {
                            ...rest,
                            veggSoner: [
                              {
                                id: uuidv4(),
                                ...(state.allWallsAgainstTheOpen
                                  ? { tilliggendeRom: TilliggendeRom.MotDetFri }
                                  : {})
                              }
                            ]
                          }
                    )
                  }
                : {};

              etasjer = [
                ...(state.userInput?.bygningsdetaljer?.etasjer || []),
                ...Array.from(
                  Array(numericValue - nonBasementFloorCount).keys()
                ).map((i) => ({
                  id: uuidv4(),
                  erKjeller: false,
                  etasjeNummer: nonBasementFloorCount + i + 1,
                  ...inheritedValues
                }))
              ];
            } else if (numericValue < nonBasementFloorCount)
              etasjer = (etasjer || []).filter(
                (etasje) =>
                  etasje.erKjeller ||
                  (!etasje.erKjeller && etasje.etasjeNummer <= numericValue)
              );
          }

          state.userInput.bygningsdetaljer.etasjer = etasjer;
          state.userInput.bygningsdetaljer[field] = numericValue;
          return;
        }

        if (
          field === 'basement' ||
          (field === 'harBoligenKjeller' && value === false)
        ) {
          const booleanValue = value as boolean;

          const basement = state.userInput?.bygningsdetaljer?.etasjer?.find(
            (etasje) => etasje.erKjeller
          );

          if (booleanValue && !basement) {
            const firstFloor = state.userInput?.bygningsdetaljer?.etasjer?.[0];

            const inheritedValues: Partial<Etasje> = state.allFloorsSameShape
              ? {
                  bygningsForm: firstFloor?.bygningsForm,
                  gjennomsnittligEtasjehoyde:
                    firstFloor?.gjennomsnittligEtasjehoyde,
                  vegger: firstFloor?.vegger?.map(({ veggSoner, ...rest }) =>
                    state.allFloorsSameWallConstruction
                      ? {
                          ...rest,
                          veggSoner: veggSoner.map(
                            // eslint-disable-next-line
                            ({ vinduer, ...veggsoneAttributes }) => ({
                              ...veggsoneAttributes,
                              id: uuidv4()
                            })
                          )
                        }
                      : {
                          ...rest,
                          veggSoner: [
                            {
                              id: uuidv4(),
                              ...(state.allWallsAgainstTheOpen
                                ? { tilliggendeRom: TilliggendeRom.MotDetFri }
                                : {})
                            }
                          ]
                        }
                  )
                }
              : {};

            etasjer = [
              {
                id: uuidv4(),
                erKjeller: true,
                etasjeNummer: 1,
                ...inheritedValues
              },
              ...(etasjer || [])
            ];
          }

          let heleEllerDelerAvKjellerErOppvarmet = false;

          if (!booleanValue && basement)
            etasjer = etasjer?.filter((etasje) => !etasje.erKjeller);

          if (booleanValue && field === 'basement') {
            heleEllerDelerAvKjellerErOppvarmet = true;
          }

          state.userInput.bygningsdetaljer = {
            ...state.userInput?.bygningsdetaljer,
            heleEllerDelerAvKjellerErOppvarmet,
            etasjer: etasjer,
            ...(field === 'harBoligenKjeller' ? { [field]: booleanValue } : {})
          };
          return;
        }
      }

      if (field === 'harBoligenKjeller' && value === true) {
        delete state.userInput?.bygningsdetaljer
          ?.tilliggendeRomGulvIForsteEtasje;
      }

      if (TekniskUtstyrKeys.includes(field as TekniskUtstyrKey) && !value) {
        const energySource =
          energySourceUnitMap[field as TekniskUtstyrKey]?.energySource;

        if (energySource && state.userInput?.energibruk) {
          state.userInput = {
            ...recursivelyHandleNestedChange<UserInput>(
              state.userInput,
              objectPath,
              value
            )
          };

          state.userInput.energibruk = state.userInput.energibruk?.filter(
            (item) => item.energikilde !== energySource
          );
          return;
        }
      }

      state.userInput = recursivelyHandleNestedChange<UserInput>(
        state.userInput,
        objectPath,
        value
      );
    }
  );
  builder.addCase(
    RegistrationAction.UPDATE_DETAILING_STEPS,
    (state, action) => {
      const {
        payload: { step, value }
      } = action as UpdateDetailingStepsAction;

      if (value) {
        state.userInput.detaljeringssteg[step] = value;
        return;
      }

      switch (step) {
        case DetailingStep.Bygningsform:
          delete state.userInput?.bygningsdetaljer?.terrengskjerming;

          state.userInput.bygningsdetaljer.etasjer =
            state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) => {
              delete etasje.bygningsForm;
              delete etasje.gjennomsnittligEtasjehoyde;
              delete etasje.vegger;
              return etasje;
            });
          state.allFloorsSameWallConstruction = false;
          state.allFloorsSameShape = false;
          state.calculatedArea = undefined;

          break;

        case DetailingStep.Vegger:
          state.userInput.bygningsdetaljer.etasjer =
            state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) => {
              delete etasje.vegger;
              return etasje;
            });
          state.allFloorsSameWallConstruction = false;
          state.allFloorsSameShape = false;
          state.calculatedArea = undefined;
          break;

        case DetailingStep.Vinduer:
          state.userInput.bygningsdetaljer.etasjer =
            state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) => {
              etasje.vegger?.map((vegg) => {
                vegg.veggSoner.map((veggsone) => {
                  delete veggsone.vinduer;
                  return veggsone;
                });
              });
              return etasje;
            });
          break;

        case DetailingStep.Gulv:
          state.userInput.bygningsdetaljer.etasjer =
            state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) => {
              delete etasje.gulvSoner;
              return etasje;
            });

          break;

        case DetailingStep.Ytterdorer:
          delete state.userInput?.bygningsdetaljer?.ytterDorer;
          break;
        case DetailingStep.Energibruk:
          delete state.userInput?.energibruk;
          break;
        case DetailingStep.Lekkasjetall:
          delete state.userInput?.bygningsdetaljer?.konstruksjonstetthet;
          break;
        case DetailingStep.Takkonstruksjon:
          delete state.userInput?.bygningsdetaljer?.tak;
          break;
      }

      state.userInput.detaljeringssteg[step] = value;
    }
  );

  builder.addCase(RegistrationAction.GULVSONE_UPDATE, (state, action) => {
    const {
      payload: { etasjeId, value, field, gulvsoneId }
    } = action as GulvsoneUpdateAction;

    state.userInput.bygningsdetaljer.etasjer =
      state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
        etasje.id === etasjeId
          ? {
              ...etasje,
              gulvSoner: etasje.gulvSoner?.map((sone) => {
                if (sone.id !== gulvsoneId) {
                  return sone;
                }

                if (
                  field === 'tilliggendeRom' &&
                  value === TilliggendeRom.OppvarmetRom
                ) {
                  delete sone.gulvKonstruksjon;
                }

                return { ...sone, [field]: value };
              }) || [{ id: uuidv4(), [field]: value }]
            }
          : etasje
      );

    const maintainGrunnforhold =
      state.userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
        etasje.gulvSoner?.some(
          (sone) => sone.tilliggendeRom === TilliggendeRom.MotGrunn
        )
      );

    if (!maintainGrunnforhold) {
      delete state.userInput?.bygningsdetaljer?.grunnforhold;
    }
  });

  builder.addDefaultCase((state, action) => {
    return registerReducer(state, action as RegistrationActionTypes);
  });
});
