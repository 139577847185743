/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { Box, Button, Grid, Hidden, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getBuildingScreenPath } from '../../../../../../../utils/navigation';
import { disabledFontColor } from '../../../../utils';
import { BuildingDetails } from '../../../../../../../types/building';
import { useMediaQuery } from '../../../../../../../hooks/useMediaQuery';

import { BuildingInfoWrapper } from './buildingInfoWrapper';
import { PublishedDate } from './publishedDate';
import { EnergyRatingIndicatorWrapper } from './energyRatingIndicatorWrapper';

const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.paper};
  border: ${({ theme }) => `thin solid ${theme.palette.divider}`} !important;
  position: relative;
  grid-column: 1;
  width: 100%;
`;

const StyledLink = styled(({ isTilbygg, ...props }) => <Link {...props} />)<{
  isTilbygg?: boolean;
}>`
  display: flex;
  overflow: hidden;
  background-color: ${({ theme, isTilbygg }) =>
    !isTilbygg ? 'transparent' : theme.palette.action.disabledBackground};
  transition: ${({ theme }) =>
    `${theme.transitions.duration.short}ms  ${theme.transitions.easing.easeInOut}`};
  color: ${({ isTilbygg }) => (!isTilbygg ? 'inherit' : disabledFontColor)};

  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.focus};
  }
`;

type BuildingLinkProps = {
  isTilbygg?: boolean;
  building: BuildingDetails;
  hasMenu?: boolean;
  minimizedView?: boolean;
};

/**
 * 'isTilbygg' prop is not derived from the building but instead added explicit from the outside.
 * This is because we want the component to have a different background color in the search
 * results, but we do not want the background color to appear on the 'buildings' and 'other buildings' tabs.
 */
export const BuildingLink: FC<BuildingLinkProps> = ({
  isTilbygg,
  building,
  hasMenu,
  minimizedView
}) => {
  const { t } = useTranslation();
  const { xsScreen } = useMediaQuery();

  const energiattest = building.gyldigeEnergiattester[0];

  return (
    <StyledLinkWrapper>
      <StyledLink
        isTilbygg={isTilbygg}
        className="fvibs"
        to={{
          pathname: getBuildingScreenPath({
            buildingId: building.energiObjektId
          })
        }}
      >
        <Grid
          container
          spacing={1}
          p={{ xs: 2, sm: 3 }}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Grid
            item
            xs
            container
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <Grid item xs>
              <BuildingInfoWrapper
                minimizedView={minimizedView}
                building={building}
              />
            </Grid>

            <Hidden smDown>
              <PublishedDate date={energiattest?.publisertDato} />
            </Hidden>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            item
            mt={{ xs: -1, sm: undefined }}
          >
            <EnergyRatingIndicatorWrapper energiattest={energiattest} />
          </Grid>
        </Grid>
      </StyledLink>

      {!hasMenu && !!energiattest?.attestUri && xsScreen && (
        <Box p={2}>
          <Button fullWidth href={energiattest?.attestUri} variant="secondary">
            {t('downloadEnergyCertificateAsPDF')}
          </Button>
        </Box>
      )}
    </StyledLinkWrapper>
  );
};
